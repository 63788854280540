import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "gatsby-image"
import SEO from "../components/seo"

const credits = [
  {
    name: 'Bombshell Miniatures',
    url: '//www.airlockgames.com'
  },
  {
    name: 'Dark Sword Miniatures',
    url: '//www.darkswordminiatures.com/mainwebsite_html/about.html'
  },
  {
    name: 'Freebooter Miniatures',
    url: '//www.freebooterminiatures.de'
  },
  {
    name: 'Grenadier Models'
  },
  {
    name: 'Iron Wind Metals',
    url: '//www.ironwindmetals.com/d/index.php'
  },
  {
    name: 'Kabuki Models',
    url: '//www.kabukimodels.com/en/'
  },
  {
    name: 'Mannequin Miniatures'
  },
  {
    name: 'Micro Art Studio',
    url: '//www.microartstudio.com'
  },
  {
    name: 'Morland Studios',
    url: '//morlandstudios.com'
  },
  {
    name: 'Reaper Miniatures',
    url: '//www.reapermini.com'
  },
  {
    name: 'Studio McVey',
    url: '//studiomcvey.highwire.com'
  },
  {
    name: 'Sukubus Studio',
    url: '//www.sukubusstudio.com'
  },
  {
    name: 'Ex Manus Studios',
    url: '//www.ex-manus.com'
  },
  {
    name: 'KaYo Miniatures',
    url: '//www.kayominiatures.com'
  },
  {
    name: 'Michael Kontraros Collectibles',
    url: '//www.mkontraros.com'
  },
  {
    name: 'Atomico',
    url: '//www.atomicopress.com'
  },
  {
    name: 'Creature Caster',
    url: '//www.creaturecaster.com'
  },
  {
    name: 'Dark Fable Miniatures',
    url: '//www.darkfableminiatures.com'
  }
];

const CreditsPage = ({ data }) => (
  <Layout>
    <SEO title="Credits" />
    <Image fluid={data.illustration.childImageSharp.fluid} />
    <div>
      <p>Our adventures in Th'eia wouldn't be the same without a little inspiration from some very talented modellers.</p>
      <ul>
        {credits.map(({ name, url }) => <li>{url ? <a href={url}>{name}</a> : <span>{name}</span>}</li>)}
      </ul>
    </div>
    <p>Also special thanks to:</p>
    <ul>
      <li><a href="//www.shutterstock.com">Shutterstock</a></li>
      <li><a href="//www.brainfevermedia.com">Brain Fever Media</a></li>
    </ul>
    <p>Note: While we’ve attempted to accurately label all images, errors are entirely possible. Please <a href="mailto:Feuery.et.al@gmail.com">contact us</a> if so.</p>
  </Layout>
)

export default CreditsPage

export const query = graphql`
  query {
    illustration: file(relativePath: { eq: "page_credits.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`